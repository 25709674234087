import Swiper from 'swiper/swiper-bundle';
// import Swiper, { Speed, SpaceBetween, FadeEffect, AllowTouchMove, Autoplay, Pagination } from 'swiper/swiper-bundle';
// Swiper.use([Speed, SpaceBetween, FadeEffect, AllowTouchMove, Autoplay, Pagination]);

export function mvSlider() {

    let mySwiper = new Swiper('.j-mvSlider', {
        speed: 1000,
        spaceBetween: 0,
        effect: "fade",
        allowTouchMove: false,
        autoplay: {
			delay: 3000,
			disableOnInteraction: false,
        },
        pagination: {
            el: '.p-home_mv_slider_num_main',
            // type: 'fraction',
            // formatFractionCurrent(number) {
            //     return number.toLocaleString('en', { minimumIntegerDigits: 2 })
            // },
            // formatFractionTotal(number) {
            //     return
            // }
            renderBullet: function (index, className) {
                return '<span class="' + className + '">' + 0 + (index + 1) + "</span>";
            }
        }
    });

}