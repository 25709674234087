import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export function mv() {
    gsap.registerPlugin(ScrollTrigger);

    const mvEl = document.querySelector('.j-mv');

    gsap.to(mvEl, {
        ease: 'none',
        scrollTrigger: {
            // markers: true,
            trigger: mvEl,
            start: 'top top',
            end: 'bottom top',
            scrub: true,
            pin: true,
            anticipatePin: 1,
            invalidateOnRefresh: true,
            // markers: true
        },
    });
    window.addEventListener("load", function(){
    });
}